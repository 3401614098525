import React from "react";
import AccordionGroup from "./AccordionGroup";
import Accordion from "./Accordion";
import styled from "styled-components";

const CenteredText = styled.p`
  @media (${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }
`;

const AccordionsHolder = () => {
  return (
    <AccordionGroup>
      <Accordion scrollTo={true} title={<p>What is Our House trying to do?</p>}>
        <CenteredText>
          Our House is building a popular case for democratic reform through a
          new People’s Charter.
        </CenteredText>
        <p>We will do this by:</p>
        <ol>
          <li>
            <b>Demonstrating the possibilities of people power.</b>
            &nbsp; Our House will facilitate a citizen-led process where people
            explore and demonstrate the possibilities of people power, including
            citizens assemblies, community organising and proportional
            representation. Through this, people will see and experience real
            power, building a movement to demand a new democracy that goes
            beyond existing understanding.
          </li>
          <li>
            <b>Effective public storytelling.</b>&nbsp; News of the People's
            Charter and the participatory process will ripple through
            traditional and independent media. Through collective imagination,
            story-writing and -telling, people will see the Charter as a
            credible demand for a new democracy.
          </li>
          <li>
            <b>Campaigning and storytelling.</b>&nbsp; The People’s Charter will
            be developed by and for the people, with strong engagement and
            championing from those closest to existing forms of power, such as
            politicians and civil society leaders.
          </li>
        </ol>
      </Accordion>
      <Accordion scrollTo={true} title={<p>What is a People's Charter?</p>}>
        <p>
          Britain has a rich history of political systems change being driven by
          those outside of power, from the &nbsp;
          <a
            href="https://www.parliament.uk/about/living-heritage/transformingsociety/electionsvoting/chartists/overview/chartistmovement/"
            target="_blank"
            rel="noopener noreferrer"
          >
            chartists
          </a>
          &nbsp; of the 19th century to &nbsp;
          <a
            href="https://academic.oup.com/pa/article/62/4/537/1538934?login=false"
            target="_blank"
            rel="noopener noreferrer"
          >
            Charter 88.
          </a>
          &nbsp; In the face of political extremism, a dysfunctional electoral
          system, and widespread apathy, we desperately need a new vision for
          democracy that sets out demands for meaningful political systems
          change.
        </p>
        <p>
          Through the development of the People’s Charter, we’ll be asking
          people across the UK:
        </p>
        <CenteredText>
          <strong>
            How can we shape our country so that it works for everyone?
          </strong>
        </CenteredText>
        <p>The People’s Charter will set out:</p>

        <ul>
          <li>Values -</li>
          <ul>
            <li>What does a country that works for all of us look like? </li>
            <li>What are the underlying values that this future represents?</li>
          </ul>
          <li>Demands -</li>
          <ul>
            <li>
              What would a democratic system that embodies these values look
              like?
            </li>
            <li>What are our specific demands for a new democracy?</li>
          </ul>
        </ul>
      </Accordion>
      {/* <Accordion scrollTo={false} title={<p>Why now?</p>}>
            <p></p>
          </Accordion> */}
      <Accordion scrollTo={true} title={<p>What&apos;s the plan?</p>}>
        <p>
          Our House will launch in 2025. There will be three core components:
        </p>
        <ul>
          <li>
            <b>Local charter development.</b>&nbsp; The first People’s Charters
            will be developed in 100 local areas across the UK. Established
            community groups and local people who want to build people power
            will run processes with their community so that people can
            experience and shape demands for a new democracy. This will form a
            bottom-up movement towards political systems change. If you want to
            build a charter in your area, please complete the ‘get involved’
            form below.
          </li>
          <li>
            <b>UK-wide charter development.</b>
            &nbsp; 100 people from across the UK will participate in a national
            assembly to imagine a new democratic system. We are building a
            creative process that will draw on the&nbsp;
            <a
              href="https://medium.com/our-house/unpacking-the-participatory-democracy-toolkit-c268290f1982"
              rel="noreferrer noopener"
              target="_blank"
            >
              best of participatory democracy,
            </a>
            &nbsp; where people will consider options for political systems
            change including how we are represented, from voting to random
            selection. We’ll also bring together a non-partisan network of
            politicians and civil society leaders from across the four nations
            to facilitate and input into the citizen-led process.
          </li>
          <li>
            <b>Campaigning and storytelling.</b>&nbsp; In partnership with
            filmmakers, journalists, media organisations, campaigners, and
            artists, we will amplify the People’s Charter and the people who
            have shaped it across local and national channels. This will include
            forum participants sharing their experiences on local news networks,
            in engaging content on social media, and through documentary
            filmmaking.
          </li>
        </ul>
        <p>
          At the end of the process, the people who have participated in Our
          House will have created the first citizen-led People’s Charter in the
          UK. We’ll celebrate the values and demands that make up the Charter
          through national and local demonstrations, ultimately, presenting the
          charter to parliament. The demands for change will be driven through
          the existing campaign ecosystem, while people and organisations across
          the country will pledge their commitments to enact the changes we
          want.
        </p>
      </Accordion>
      <Accordion scrollTo={true} title={<p>Why now?</p>}>
        <p>
          In a time of crisis and collapse, we need a democratic system that
          enables good ideas to thrive. One that enables people to work together
          and overcome their differences. But our current political system was
          designed to maintain the status quo and exaggerate division. Whether a
          citizen or politician, everyone struggles to create meaningful change
          in this system. And there are no easy choices on the road ahead of us,
          so we need a democracy that allows us to shape the country we want for
          our future.
        </p>
        <p>
          It’s clear that our Victorian political system is no longer working,
          but is voting every five years the best we can do? The new government
          has committed to explore political reform, so now is the time to build
          a movement towards a new democratic system. One that is citizen-led.
          One that is incorruptible. One that connects us all.
        </p>
        <p>Now is the time to build a new democracy: to build Our House.</p>
      </Accordion>
      <Accordion scrollTo={true} title={<p>Who is involved?</p>}>
        <p>
          Our House is an alliance of creatives, democracy practitioners,
          community organisers, activists, funders, campaigners, and more. We
          will be sharing more about those behind Our House in the coming
          months. Stay tuned.
        </p>
      </Accordion>
    </AccordionGroup>
  );
};

export default AccordionsHolder;
